import request from '@/utils/request1';

// 企业信息查询
export function getInfo(data) {
    return request({ url: '/merchant/QMerchantView2', method: 'post', data })
}

// 企业信息编辑
export function editInfo(data) {
    return request({ url: '/merchant/UMerchantView2', method: 'post', data })
}

// 从业人员查询
export function employeeViewList(data) {
    return request({ url: '/employee/QEmployeeView2', method: 'post', data })
}

// 从业人员新增
export function employeeViewAdd(data) {
    return request({ url: '/employee/AEmployeeView2', method: 'post', data })
}

// 从业人员编辑
export function employeeViewEdit(data) {
    return request({ url: '/employee/UEmployeeView2', method: 'post', data })
}

// 从业人员删除
export function employeeViewDel(data) {
    return request({ url: '/employee/DEmployeeView2', method: 'post', data })
}

// 制度管理查询
export function systemList(data) {
    return request({ url: '/rules/QRulesApi', method: 'post', data })
}

// 制度管理新增
export function systemAdd(data) {
    return request({ url: '/rules/ARulesApi', method: 'post', data })
}

// 制度管理编辑
export function systemEdit(data) {
    return request({ url: '/rules/URulesApi', method: 'post', data })
}

// 制度管理删除
export function systemDel(data) {
    return request({ url: '/rules/DRulesApi', method: 'post', data })
}

